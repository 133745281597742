<template>
  <div>
    <NavBar class="fixed top-0 left-0 right-0"></NavBar>

    <secondary-navbar
      title="Delivery Location"
      :showbroker="false"
      :showaction="false"
      :showback="true"
    />

    <div class="w-full mt-6 grid grid-cols-10 hidden sm:grid" id="page-login">
      <div class="col-span-2 sm:block 0 background-f4">
        <left-side-strip
          :showBrokerButton="false"
          :showPie="false"
          :showWine="false"
        />
      </div>

      <div class="col-span-8 m-20">
        <div class="grid grid-cols-5 gap-4">
          <div class="col-span-5 oeno_delivery_text pb-10">
            Please choose where you would like the wines to be sent / delivered
            to once you have purchased the wine.
          </div>
        </div>

        <div class="grid grid-cols-5 gap-4 pb-10">
          <div class="col-span-4">
            <span class="oeno_delivery_info_text"
              ><b>Option 1</b><br/> Keep these wines stored in our state-of-the-art
              facility, ensuring that your wine is kept in optimal
              conditions<br /><br
            /></span>
          </div>
          <div class="">
            <button
              type="submit"
              @click="submitForm(1)"
              class="w-48 h-10 oeno_button_black mt-2 align-center"
            >
              CHOOSE
            </button>
          </div>
        </div>


        <div class="grid grid-cols-5 gap-4 pb-10">
          <div class="col-span-4">
            <span class="oeno_delivery_info_text"
              ><b>Option 2</b><br/> Move your wines from out Bonded store to
                  your own bond<br /><br
            /></span>
          </div>
          <div class="">
            <button
              type="submit"
              @click="submitForm(2)"
              class="w-48 h-10 oeno_button_black mt-2 align-center"
            >
              CHOOSE
            </button>
          </div>
        </div>



        <div class="grid grid-cols-5 gap-4 pb-10">
          <div class="col-span-4">
            <span class="oeno_delivery_info_text"
              ><b>Option 3</b><br/> Deliver out of the bond but this will incur
                  duty and VAT charges<br /><br
            /></span>
          </div>
          <div class="">
            <button
              type="submit"
              @click="submitForm(3)"
              class="w-48 h-10 oeno_button_black mt-2 align-center"
            >
              CHOOSE
            </button>
          </div>
        </div>


      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/layout/navbar.vue";
import SecondaryNavbar from "@/components/layout/SecondaryNavbar.vue";
import Footer from "@/components/layout/footer.vue";
import LeftSideStrip from "@/components/pageparts/LeftSideStrip.vue";

export default {
  components: {
    NavBar,
    Footer,
    LeftSideStrip,
    SecondaryNavbar,
  },
  data() {
    return {
      deliveryAddress: null,
      updateStatus: false,
      orderData: null,
    };
  },
  created: function () {
    this.setDefaultData();
  },
  methods: {
    setDefaultData() {
      this.deliveryAddress = this.$store.state.orderData.deliveryAddress;
      this.orderData = this.$store.state.orderData;
    },
    submitForm(target) {
      if (target == 1) {
        this.orderData.target = "inbond";
      }
      if (target == 2) {
        this.orderData.target = "outbond";
      }
      if (target == 3) {
        this.orderData.target = "outhome";
      }

      this.$store.dispatch("storeOrderData", this.orderData).then(() => {
        if (target == 1) {
          this.$router.push({
            name: "page--buy--invest-pay",
            params: {
              id: this.$route.params.id,
              source: this.$route.params.source,
              sell_size: this.$route.params.sell_size,
            },
          });
        }
        if (target == 2) {
          this.$router.push({
            name: "page--buy--invest-outbond",
            params: {
              id: this.$route.params.id,
              source: this.$route.params.source,
              sell_size: this.$route.params.sell_size,
            },
          });
        }
        if (target == 3) {
          this.$router.push({
            name: "page--buy--invest-outhome",
            params: {
              id: this.$route.params.id,
              source: this.$route.params.source,
              sell_size: this.$route.params.sell_size,
            },
          });
        }
      });
    },
  },
};
</script>
